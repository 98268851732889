import $http from "./index";
import qs from "qs";

import { PLATFROM_CONFIG } from "../../public/config";
let base = PLATFROM_CONFIG.baseURL;

///积分商城
export const ListPointsMall = (data) => {
  return $http.get(`/app/business/pointsmall/back/pagelist`, {
    params: data,
  });
};
//新增
export const CreatePointsMall = (data) => {
  return $http.post(`/app/business/pointsmall/back/new`, data);
};
///修改
export const updatePointsMall = (data) => {
  return $http.post(`/app/business/pointsmall/back/update`, data);
};
//根据id 获取一条商品的信息
export const PointsMallById = (id) => {
  return $http.get(`/app/business/pointsmall/back/detail?id=` + id, {});
};

//根据id 删除一条商品分类的信息
export const DeltePointMall = (data) => {
  return $http.get(`/app/business/pointsmall/back/delete`, {
    params: data,
  });
};

//获取积分分类表名字
export const GetAllListAsync = (data) => {
  return $http.get(`/app/business/pointsexchangetype/getall`, {
    params: data,
  });
};

//多张图片新增（详情）
export const setPointsMallPic = (data) => {
  return $http.post(`/app/business/pointsmall/back/setdescpic`, data);
};

//获取详情页图片
export const getPointImg = (data) => {
  return $http.get(`/app/business/pointsmall/back/getdetailimg`, {
    params: data,
  });
};

//获取兑换明细页面
export const ListExchangeDetails = (data) => {
  return $http.get(`/app/business/exchangedetails/back/pagelist`, {
    params: data,
  });
};
//查询优惠券
export const getCouponsRule = (data) => {
  return $http.post(`/app/info/crmInterface/getCouponsRule`, data);
};
export const ExchangeLength = () => {
  return $http.get(`/app/business/exchangedetails/back/GetExchangeLength`, {});
};
// 更新排序
export const updateSort = (data) => {
  return $http.post("/app/business/pointsmall/back/updateSort", data);
};
// 补发积分
export const reissueIntegral = (data) => {
  return $http.post("/app/business/exchangedetails/rollBackPoint", data);
};
